<template lang="html" src="./pagePartOrders.template.vue"></template>

<style lang="scss" src="./pagePartOrders.scss"></style>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pagePartOrders.i18n');

export default {
  name: 'PagePartOrders',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      orders: [],
      part: null,
      loading: false,
      search: '',
      headers: [],
      statusFilter: [],
      paymentFilter: [],
      filterObject: {
        status: [
          {
            name: this.$t('NEWORDER'),
            value: 'NEW', 
            img: 'new.png',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('INPROGRESS'),
            value: 'INPROGRESS', 
            iconColor: 'primary',
            icon: 'fas fa-spinner',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('DELIVERED'),
            value: 'DELIVERED', 
            iconColor: 'success',
            icon: 'fas fa-check',
            number: 0,
          }
        ],
        paymentStatus: [
          {
            name: this.$t('WAITING'),
            value: 'WAITING', 
            img: 'creditCard.svg',
            number: 0,
          },
          {
            name: this.$t('PAID'),
            value: 'PAID', 
            img: 'creditCardCheck.svg',
            number: 0,
          },
          {
            name: this.$t('ERROR'),
            value: 'ERROR', 
            img: 'creditCardTimes.svg',
            number: 0,
          },
        ]
      },
    };
  },
  created() {
    this.getBrandPart();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('status'),
        value: 'status',
        align: 'center',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value)) return true;
        }
      },
      {
        text: '',
        sortable: false,
        value: 'paymentStatus',
        filter: (value) => {
          if (this.paymentFilter.length === 0) return true;
          if (this.paymentFilter.includes(value)) {
            return true;
          }
        },
      },
      {
        text: this.$t('invoiceID'),
        value: 'invoiceNumber',
        align: 'center',
      },
      {
        text: this.$t('purchaseOrder'),
        value: 'quote.purchaseOrderNumber',
        align: 'center',
      },
      {
        text: this.$t('price'),
        value: 'quote.selling.totalAmount',
        align: 'center',
      },
      { text: this.$t('created'), value: 'created', align: 'center' },
      {
        text: this.$t('action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    customFilter(items, search, filter) {
      if (typeof items === 'number' ) {
        items = items.toString().replace('.', ',');
      }
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    getBrandPart() {
      this.loading = true;
      this.$apiInstance
        .getBrandPart(this.$appConfig.currentBrand, this.$route.params.partUUID)
        .then(
          (partData) => {
            this.part = partData;
            this.getBrandPartOrders();
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    getBrandPartOrders() {
      /**
       * GET BRAND ORDERS
       */
      this.$apiInstance
        .getBrandPartOrders(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID
        )
        .then((data) => {
          this.orders = data;
          /**
           * Reset Filter Object
           */
          for (const key in this.filterObject) {
            for (const obj of this.filterObject[key]) {
              obj.number = 0;
            }
          }
          this.orders.forEach(order => {
            this.filterObject.status.find(x => x.value === order.status).number++;
            this.filterObject.paymentStatus.find(x => x.value === order.paymentStatus).number++;
          });
        })
        .catch((error) => {
          /**
           * ERROR GET BRAND QUOTES
           */
          ApiErrorParser.parse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshPage() {
      this.loading = true;
      this.getBrandPartOrders();
    },
    updateFilter(update) {
      this.statusFilter = update.status;
      this.paymentFilter = update.paymentStatus;
      if (this.paymentFilter.length > 0 && !this.statusFilter.includes('NEW') && !this.paymentFilter.includes('PAID')) {
        this.statusFilter.push('NEW');
      }
      
      this.filterObject.status.forEach(status => {
        if(this.paymentFilter.length > 0 && !this.paymentFilter.includes('PAID') && status.value !== 'NEW' ) {
          status.disabled = true;
          this.statusFilter.length = 0;
          this.statusFilter.push('NEW');
        } else {
          status.disabled = false;
        }
      });
    },
  },
};
</script>
